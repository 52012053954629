import React, { BaseSyntheticEvent, useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as style from "./form-layout.module.scss";
import { Button, Col, FloatingLabel, Form, Toast } from "react-bootstrap";

const FormLayout = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const formSubmit = async (e: BaseSyntheticEvent) => {
    e.preventDefault();
    const formData = new FormData(e.target),
      formDataObj = Object.fromEntries(formData.entries());
    if (
      !formDataObj.name ||
      !formDataObj.message ||
      !formDataObj.email ||
      !formDataObj.subject
    )
      return;
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        name: formDataObj.name,
        email: formDataObj.email,
        subject: formDataObj.subject,
        message: formDataObj.message,
      }),
    };
    try {
      await fetch(
        "https://qmujc7sannr36vzy6vdlxacwya0nizcv.lambda-url.eu-central-1.on.aws",
        requestOptions
      );
      setMessage(t("common.message.sent"));
    } catch (err) {
      setMessage(t("common.message.fail"));
    }

    setShow(true);
    e.target.reset();
  };

  return (
    <Col className={style.contactForm}>
      <div className={style.formHeader}>{t("form.head1")}</div>
      <span style={{ fontSize: "14px", lineHeight: "1rem" }}>
        {t("form.text1")}
      </span>
      <Form onSubmit={formSubmit}>
        <FloatingLabel
          controlId="cfName"
          label={t("form.name1")}
          className="mb-3 mt-3"
        >
          <Form.Control
            type="text"
            placeholder={t("form.name1")}
            required
            name="name"
          />
          <Form.Control.Feedback type="invalid">
            {t("form.alert1")}
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel
          controlId="cfEmail"
          label={t("form.email1")}
          className="mb-3"
        >
          <Form.Control
            type="email"
            placeholder={t("form.email1")}
            name="email"
          />
          <Form.Control.Feedback type="invalid">
            {t("form.alert1")}
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel
          controlId="cfSubject"
          label={t("form.subject1")}
          className="mb-3"
        >
          <Form.Control
            type="text"
            placeholder={t("form.subject1")}
            name="subject"
          />
          <Form.Control.Feedback type="invalid">
            {t("form.alert1")}
          </Form.Control.Feedback>
        </FloatingLabel>
        <FloatingLabel
          controlId="cfMessage"
          label={t("form.message1")}
          className="mb-3"
        >
          <Form.Control
            as="textarea"
            placeholder="{t('form.message1')}"
            style={{ height: "9rem" }}
            name="message"
          />
          <Form.Control.Feedback type="invalid">
            {t("form.alert1")}
          </Form.Control.Feedback>
        </FloatingLabel>
        <div className="d-grid gap-2">
          <Button
            className={style.button2}
            style={{ width: "100%", borderRadius: "10px" }}
            type="submit"
          >
            {t("form.submit")}
          </Button>
        </div>
      </Form>
      <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide>
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">{t("common.info")}</strong>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </Col>
  );
};
export default FormLayout;
