// extracted by mini-css-extract-plugin
export var banner = "contact-module--banner--2Sr94";
export var bannerC = "contact-module--bannerC--NzPUn";
export var bannerImage = "contact-module--bannerImage--oyroc";
export var bgcircle = "contact-module--bgcircle--UHUFl";
export var contactInfo = "contact-module--contactInfo--N9F8z";
export var contactInfoHeader = "contact-module--contactInfoHeader--SBbZ9";
export var contactMain = "contact-module--contactMain--7BhtX";
export var contactPage = "contact-module--contactPage--rqTty";
export var layertext = "contact-module--layertext--8SCAj";
export var map = "contact-module--map--RAQc4";
export var mb = "contact-module--mb--mEg4E";
export var mt = "contact-module--mt--78zIu";
export var office = "contact-module--office--apJZ0";
export var pointprimary = "contact-module--pointprimary--tLtPd";
export var pointsecondary = "contact-module--pointsecondary--mffKI";
export var socialIcon = "contact-module--socialIcon--1v5k4";
export var testimonial = "contact-module--testimonial--WGitG";
export var tp = "contact-module--tp--0nvs1";