import { graphql } from "gatsby";
import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import BaseLayout from "../components/layouts/base-layout";
import { Col, Container, Ratio, Row } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import FormLayout from "../components/layouts/form.layout";
import HeaderLayout from "../components/layouts/header-layout";
import * as style from "../styles/contact.module.scss";
// markup
const ContactPage = () => {
  const { t } = useTranslation();
  return (
    <BaseLayout>
      <HeaderLayout></HeaderLayout>

      <Container className={style.contactMain}>
        <Col className={style.contactInfo}>
          <div>
            <span className={style.contactInfoHeader}>
              {t("contact.head1")}
            </span>
            <Row style={{ paddingBottom: "2rem" }}>
              <Col>
                <span className={style.office}>{t("contact.office1")}</span>
                <span
                  style={{
                    fontSize: "14px",
                    lineHeight: "20px",
                    color: "white",
                  }}
                >
                  {t("contact.adress1")}
                </span>
              </Col>
            </Row>
            <Row className={style.map}>
              <Col md="8">
                <Ratio style={{ height: "200px" }}>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3125.046016237136!2d27.14096796555202!3d38.44040605199009!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14bbd844c8bbf72b%3A0x477b02cd611c42a0!2zQWxzYW5jYWssIEF0YXTDvHJrIENkLiBObzozNTQgRDpLYXQ6NywgRGFpcmU6MTMsIDM1MjIwIEtvbmFrL8Swem1pcg!5e0!3m2!1str!2str!4v1676887219806!5m2!1str!2str"
                    width="1000px"
                    loading="lazy"
                  ></iframe>
                </Ratio>
              </Col>
            </Row>
          </div>
          <div style={{ paddingBottom: "2rem" }}>
            <span className={style.office}>{t("social.head1")}</span>
            <div
              className="align-content-center d-flex"
              style={{ alignItems: "center", cursor: "pointer" }}
              onClick={() =>
                window.open("https://www.instagram.com/fenom.io/", "_blank")
              }
            >
              <div className={style.socialIcon}>
                <StaticImage
                  src="../images/instagram.svg"
                  alt="instagram"
                ></StaticImage>
              </div>
              <span
                style={{
                  fontSize: "14px",
                  lineHeight: "1.5rem",
                  color: "white",
                }}
              >
                {t("social.instagram")}
              </span>
            </div>
          </div>
        </Col>
        <FormLayout />
      </Container>
    </BaseLayout>
  );
};

export default ContactPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
